<template>
  <div>
    <!-- <h3>Privacy Policy</h3> -->

    <p>www.pickitperfect.com Privacy Policy</p>
    <p><strong>Last updated August 19, 2024</strong></p>

    <p><strong><u>INTRODUCTION</u></strong></p>
    <p>This Privacy Policy (the “Privacy Policy”) serves to inform you of our policies and procedures regarding the collection, use, and disclosure of the information we receive when you access and use the website (the “Site”), services, software, content, and all other products and services (all of the foregoing collectively, the “Services”) owned, controlled, or offered, directly or indirectly, by Pick it Perfect, LLC or www.pickitperfect.com (together with its subsidiaries and affiliates, “Pick it Perfect”, “we,” “our” or “us”). Pick it Perfect believes that the privacy of its users is paramount and strives to use Personal Information (as defined below) only in ways outlined in this Privacy Policy. The Privacy Policy is incorporated by reference into our Terms of Service. Any terms used herein and not defined will have the meanings given to them in the Terms of Service. By using the Services, you hereby warrant and represent that you have read, understand, and agree to this Privacy Policy and the Terms of Service. PLEASE DO NOT USE OR ACCESS THE SERVICES IF YOU DO NOT AGREE TO THIS PRIVACY POLICY.</p>

    <p><strong><u>WHAT INFORMATION DO WE COLLECT</u></strong></p>
    <p><strong>Personal information you disclose to us.</strong></p>
    <p><strong>In Short:</strong> We collect personal information that you provide to us.</p>
    <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and services, participate in activities on the Services, or otherwise when you contact us.</p>

    <p><strong>Personal Information Provided by You.</strong> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
    <ul>
        <li>Names</li>
        <li>Email addresses</li>
        <li>Mailing addresses</li>
        <li>Passwords</li>
        <li>Wishlist information</li>
        <li>Preferences</li>
    </ul>
    <p><strong>Sensitive Information.</strong> We do not process sensitive information.</p>
    <p><strong>Payment Data.</strong> We do not collect payment data.</p>
    <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

    <p><strong><u>Automatically Collected Information</u></strong></p>
    <p>Most of the data we collect in and through the Site and the Services is technical in nature and is collected and processed automatically through so-called application programming interfaces, software development kits, cookies, and similar software-based technologies. Alone or in combination with other data, such automatically collected data may constitute Personal Information. The data we may collect by automated means may include, without limitation:</p>
    <ul>
        <li>Usage data: including, but not limited to, search terms entered, and pages viewed.</li>
        <li>Network and internet information: including, but not limited to, URLs, Internet Protocol addresses, bounce rates, use of spoofing, active (TCP/IP) ports, number of sessions initiated, click streams, location information, and network/Wi-Fi access points.</li>
        <li>Information we collect on the use of the Site via cookies: please see the “How We Use Cookies and Other Technologies” section below for more information.</li>
        <li>Google Analytics is an element of the Site. By using cookies, Google Analytics collects and stores data such as time of visit, pages visited, time spent on each page of the Site, the Internet Protocol address, and the type of operating system used in the devices used to access the Site. By using a browser plugin available at <a href="http://www.google.com/ads/preferences/plugin/">Google plugin</a>, you can opt out of Google Analytics.</li>
    </ul>

    <p><strong><u>Information You Post on the Services</u></strong></p>
    <p>The Services may provide chat, forum, or bulletin board tools to users and the ability to create an “avatar,” cover picture, or other customized profile and Account information for creators. Information that you provide through the use of these tools will be available to the public generally. Pick it Perfect has no obligation to keep private any information that you disclose to other users or the public using these functions. You should exercise caution when deciding to disclose Personal Information through the use of these tools or functions. We strongly recommend that you do not post any information through the Services that allows strangers to identify or locate you or that you otherwise do not want to share with the public.</p>

    <p><strong><u>Information You Share on Third Party Websites or through Social Media Services</u></strong></p>
    <p>The Services may include links to third-party websites and social media services where you will be able to post comments, stories, reviews, or other information. Your use of these third-party websites and social media services may result in the collection or sharing of information about you by these third-party websites and social media services. We encourage you to review the privacy policies and settings on the third-party websites and social media services with which you interact to make sure you understand the information that may be collected, used, and shared by those third-party websites and social media services.</p>

    <p><strong><u>INTERNATIONAL TRANSFER</u></strong></p>
    <p>Your information, including Personal Information, may be transferred to --- and maintained on --- computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the information, including Personal Information, to the United States and process it there. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

    <p><strong><u>USE OF COLLECTED INFORMATION</u></strong></p>
    <p>We use the information you provide to us for the following purposes:</p>
    <ul>
        <li>To further our legitimate interests in providing the Services, including without limitation to collect and remit payment for paid features.</li>
        <li>To administer your use of the Services and any Accounts you may have with us.</li>
        <li>To personalize your experience.</li>
        <li>To provide you Service announcements or inform you of new releases and features.</li>
        <li>To provide you with further information and offers from us or third parties that we believe you may find useful or interesting, such as newsletters, marketing, or promotional materials.</li>
        <li>To perform tasks on behalf of and according to the instructions of a third party, such as payment processors, third-party service providers, or our advertising partners.</li>
        <li>To enforce our Terms of Service.</li>
        <li>To resolve any disputes between users of the Site or between such users and us.</li>
        <li>To comply with a legal requirement or process, including, but not limited to, civil and criminal subpoenas, court orders, or other compulsory disclosures.</li>
        <li>To protect our rights, property, or safety and the rights, property, and safety of the Services, our users, or the public.</li>
        <li>To contact you to obtain feedback from you regarding the Site and the Services.</li>
    </ul>
    <p>In addition to the purposes described above, we use the information collected automatically to:</p>
    <ul>
        <li>Further our legitimate interests in monitoring and analyzing the use of the Services and for the technical administration of the Site.</li>
        <li>Improve the Site and the Services.</li>
        <li>Generate and derive useful data and information concerning the interests, characteristics, and website use behavior of our users.</li>
        <li>Verify that users of the Services meet the criteria required to process their requests.</li>
    </ul>

    <p><strong><u>THIRD PARTIES WE SHARE PERSONAL INFORMATION WITH</u></strong></p>
    <p>We may disclose Personal Information you provide to us or that we collect automatically on the Site and in and through the Services with the following categories of third parties:</p>
    <ul>
        <li>Service providers, such as payment processors, web hosting and data storage service providers, and others helping us deliver and develop the Services.</li>
        <li>Public authorities, such as law enforcement, if we are legally required to do so or if we need to protect our rights or the rights of third parties.</li>
        <li>Our subsidiaries and affiliates.</li>
        <li>A subsequent owner, co-owner, or operator of the Site and/or the Services and their advisors in connection with a corporate merger, consolidation, restructuring, the sale of substantially all of our stock and/or assets, or in connection with bankruptcy proceedings, or other corporate reorganization.</li>
        <li>Third-party merchants will receive, as needed, a creator’s shipping address, email address, and phone number for order purposes.</li>
        <li>Third parties related to our operations, such as our ordering staff, advisors, or consultants, or any party that aids us in fulfilling our services.</li>
    </ul>

    <p><strong><u>LINKS TO OTHER WEBSITES</u></strong></p>
    <p>We frequently make content or services from other websites available to you from links located on the Site. We may present links in a format that enables us to keep track of whether these links have been followed. In addition, as a creator, you may opt to link your Account to your social media account or other accounts, such as Google, in accordance with your Account settings (which you may change at any time) and respective policies of these third parties. This Privacy Policy applies only to the Site and the Services. We do not exercise control over third-party services or other websites that provide information or links from within the Site or the Services. Your interactions with these third-party services are governed by the privacy policy of the company providing them. These other sites and services may place their own cookies or other files on your computer’s browser, collect data, or solicit Personal Information from you. We encourage you to read the privacy policies and other terms of such third parties before using their services.</p>

    <p><strong><u>CONTROLLING YOUR PERSONAL INFORMATION</u></strong></p>
    <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
    <ul>
        <li>If you do not wish to be contacted by us, please email us directly at <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>.</li>
        <li>You may request details of personal information that we hold about you under the Data Protection Act 1998.</li>
        <li>If you believe that any information we are holding on you is incorrect or incomplete, please email us as soon as possible. We will promptly correct any information found to be incorrect.</li>
    </ul>

    <p><strong><u>PROTECTING INFORMATION</u></strong></p>
    <p>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password, and we urge you to take steps to keep your personal information safe by not disclosing your password. We further protect your information from potential security breaches by implementing certain technological security measures, including encryption, firewalls, and HTTPS or SSL security standards. However, these measures do not guarantee that your information will not be accessed, disclosed, altered, or destroyed by breach of such firewalls and secure server software. By using or accessing our Site, you acknowledge that you understand and agree to assume these risks.</p>

    <p><strong><u>Your Rights</u></strong></p>
    <p>We take steps to help ensure that you are able to exercise your rights regarding Personal Information about you in accordance with applicable laws. If you would like to access, review, erase, withdraw your consent, or object to, or restrict the processing of Personal Data we collect about you, you may submit a request to <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>. We will promptly review all such requests in accordance with applicable laws. Depending on where you live, you may also have a right to lodge a complaint with a supervisory authority or other regulatory agency if you believe that we have violated any of the rights concerning Personal Information about you. We encourage you to first reach out to us at <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>, so we have an opportunity to address your concerns directly before you do so.</p>

    <p><strong><u>Your GDPR and California Privacy Rights</u></strong></p>
    <p>We strive to comply with all applicable privacy and data security laws, including the EU’s General Data Protection Regulation (“GDPR”) and the California Consumer Privacy Act of 2018 (“CCPA”). We currently do not share or disclose your Personal Information to third parties for the third parties’ direct marketing purposes. If we change our practices in the future, we will implement an opt-out policy as required under California laws.</p>

    <p><strong><u>HOW WE USE COOKIES AND OTHER TECHNOLOGIES</u></strong></p>
    <p>We use cookies to maintain information about your current visit, i.e., what items you've added to your preference profile or wish lists, and whether you're currently logged in or not. We also use third-party cookies to log what pages you visit, and how long you spend on them. We use this statistical information to improve our website. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. This may prevent you from taking full advantage of the website.</p>

    <p><strong><u>AFFILIATE LINKS</u></strong></p>
    <p>Some of the links provided to buy items online, or to search on Amazon, are monetized, meaning we may earn a small commission on a subsequent order. That's what enables us to provide this service for free. This process has no impact on your purchase and is completely transparent.</p>

    <p><strong><u>CHILD PRIVACY</u></strong></p>
    <p>Only persons age 13 or older have permission to access the Site. If you are a parent or guardian and you learn that your children have provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers. Parents and guardians have the ability to create a profile for their children under the age of 13 that is viewable only through the parent or guardian’s profile. Use caution not to share identifiable information of children under the age of 13.</p>

    <p><strong><u>CHANGES TO THE PRIVACY POLICY</u></strong></p>
    <p>Our security and Privacy Policy are periodically reviewed and enhanced as necessary. This Privacy Policy might change as we update and expand the Services. You can tell when this Privacy Policy was last updated by reviewing the Last Updated legend on top of this page. We will endeavor to notify you of these changes by email but will not be liable for any failure to do so. We also encourage you to review this Privacy Policy periodically. If you do not understand any of the terms or conditions of any of our policies, you may inquire regarding the same via email at <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>. Your continued use of the Services after any change in this Privacy Policy will constitute your acceptance of such change.</p>

    <p><strong><u>CONTACTING US</u></strong></p>
    <p>If you have any concerns or questions about this Privacy Policy, please contact us at <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>.</p>

  </div>
</template>


<script>

export default {
  name: 'PrivacyPolicy',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {

  },
  created() {

  },

}
</script>

<style lang="scss" scoped>


</style>
