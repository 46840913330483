<template>
  <div>
    <privacy-policy />
  </div>
</template>

<script>
import PrivacyPolicy from './PrivacyPolicy.vue';

export default {
  name: 'PrivacyPage',
  components: {
    PrivacyPolicy

  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {
		window.scrollTo(0, 0)
  },
  created() {

  },

}
</script>

<style lang="scss" scoped>


</style>
