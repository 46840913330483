import Vue from 'vue'
import Vuex from 'vuex'

import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

// import * as modules from './lib'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { LayoutPlugin, SidebarPlugin } from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import VueRouter from 'vue-router'
import './assets/custom.scss' // important! and should be above BootstrapVue


import AppMarketingSite from './AppMarketingSite.vue'
import TemporaryHomepage from './pickItPerfect/pages/TemporaryHomepage'
import FaqPage from './pickItPerfect/pages/FaqPage'
import AboutPage from './pickItPerfect/pages/AboutPage'
import MarketingHomePage from './pickItPerfect/pages/MarketingHomePage'

import ContactPage from './pickItPerfect/staticCopy/ContactPage.vue'
import PrivacyPage from './pickItPerfect/staticCopy/PrivacyPage.vue'
import TermsPage from './pickItPerfect/staticCopy/TermsPage.vue'
import PathNotFound from './pickItPerfect/PathNotFound'

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(LayoutPlugin)
Vue.use(SidebarPlugin)
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(Vuex)

if (process.env.VUE_APP_ENV == "DEV") {
  Vue.config.productionTip = true
}

const routes = [
  { path: '/', component: TemporaryHomepage },
  { path: '/faq', component: FaqPage },
  { path: '/about', component: AboutPage },
  { path: '/soon', component: MarketingHomePage },

  { path: '/contact', component: ContactPage },
  { path: '/privacy', component: PrivacyPage },
  { path: '/terms', component: TermsPage },
  // { path: '/terms', component: PrivacyPage },
  // { path: '/support', component: SupportPage },
  { path: '/:pathMatch(.*)*', component: PathNotFound },
]

const store = new Vuex.Store({
  state: {
    currentUser: {},
  },
  mutations: {
    setCurrentUser (state, user) {
      state.currentUser = user
    },
  }
})

// new
const router = createRouter({
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

createApp(AppMarketingSite).use(store).use(router).mount('#app-marketing-site')
