<template>
  <b-container>
    <b-row>
      <b-col md="3" cols="12"></b-col>
        <b-col md="6" cols="12">
          <div class="text-center mb-5">
            <h1 class="pip-font-main brand-color" style="font-size:4rem;">Pick it Perfect</h1>
            <h4 class="font-2 brand-color">coming soon</h4>
          </div>
          <b-card>
            <div class="mb-3">Registration is currently open to select users only... If you'd like early access, please send us a note!</div>
            <!-- <contact-form-anonymous /> -->
          </b-card>
        </b-col>
      <b-col md="3" cols="12"></b-col>
    </b-row>
  </b-container>
</template>

<script>
// import ContactFormAnonymous from '../components/ContactFormAnonymous.vue'

export default {
  name: 'ContactPage',
  components: {
    // ContactFormAnonymous
  },
  data() {
    return {

    }
  },
  computed: {

  },

	mounted() {
		window.scrollTo(0, 0)
  },

  created() {

  },

  methods: {


  }

}
</script>

<style lang="scss" scoped>


</style>
