<template>
  <div>
    <b-navbar toggleable="lg" style="background:white!important;">
      <!-- <b-navbar-brand href="/" class="brand-2">Pick it Perfect</b-navbar-brand> -->
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="background:white!important;border:none;">
        <a class="" href="/"><img style="width:50px;height:auto;" src="https://storage.googleapis.com/pickitperfect-public-bucket-1/pickitperfect-user-images/logo1.png" /></a>
      </nav>      


      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item href="/dashboard">Dashboard</b-nav-item> -->
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-form>
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form> -->
          <!-- <b-nav-item v-if="testEnvironment()" style="background:lightsalmon;">STAGING</b-nav-item> -->
          <b-nav-item href="/#/about" class="brand-2">About</b-nav-item>
          <b-nav-item href="/#/faq" class="brand-2">FAQ</b-nav-item>
          <b-nav-item href="/accounts/login" class="brand-2">Log in</b-nav-item>
          <!-- <b-nav-item-dropdown v-if="currentUser.user" :text="currentUser.user.first_name || currentUser.user.username" right>
            <b-dropdown-item href="/accounts/login">Account</b-dropdown-item>
            <b-dropdown-item v-if="authenticated" href="/api-auth/logout/?next=/">Log out</b-dropdown-item>
          </b-nav-item-dropdown> -->
        </b-navbar-nav>


      </b-collapse>
    </b-navbar>


  </div>
</template>


<script>

export default {
  name: 'MarketingNavbar',
  data() {
    return {

    }
  },
  components: {
  },
  computed: {

  },
  created() {

  },
  methods: {
    testEnvironment() {
      return process.env.VUE_APP_ENV != 'PROD'
    }
  }
}
</script>

<style lang="scss" scoped>

.brand-2 {
  // font-family: 'Noto Serif', serif;
  font-family: 'Libre Franklin', sans-serif
}

</style>
