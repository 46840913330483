
<template>
  <div>
    <div id="app-marketing">
      <MarketingNavbar />
      <b-container class="mt-5">
        <router-view></router-view>
      </b-container>

    </div>
    <MarketingFooter />
  </div>
</template>

<script>
import MarketingNavbar from './components/MarketingNavbar.vue'
import MarketingFooter from './components/MarketingFooter.vue'

export default {
  name: 'AppMarketingSite',
  components: {
    MarketingNavbar,
    MarketingFooter,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
    console.log(`Marketing Site VUE_APP_HOST! ${process.env.VUE_APP_HOST}`)
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>

#app-marketing {
  min-height: 100vh;
  margin: 0;
  // background: #F1FBFD; // lighter
  padding-bottom:3rem;
}

</style>
