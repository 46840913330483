<template>
  <b-container class="mt-4" @click="openModal()" style="cursor:pointer;">

    <b-row>
      <b-col md="12" cols="12">
        <div class="mb-5 text-center">
          <h1 class="mb-2 font-brand brand-color">Pick it Perfect</h1>
        </div>
      </b-col>
      <b-col md="12" cols="12">
        <!-- <img style="width:100%;height:auto;" src="../../../assets/collections_anonymous_screenshot.png" /> -->
      </b-col>
    </b-row>

    <b-modal id="contact-modal" ref="contact-modal" hide-header hide-footer>
      <div class="mb-2">Registration is currently open to select users only. Let us know if you're interested in learning more 🤠</div>
      <!-- <contact-form-anonymous /> -->
    </b-modal>
  </b-container>
</template>

<script>
// import { createAnalyticsEvent } from '../hooks/helperFunctions'

export default {
  name: 'MarketingPage',
  components: {
    // ContactFormAnonymous
  },
  data() {
    return {

    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {

  },
  created() {

  },
  watch: {

  },

  methods: {
    openModal() {
      this.$bvModal.show('contact-modal')
    }
  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}

.large {
  font-size: 1.5rem;
}

div, span {
  font-family: Larsseit-Light;
}

</style>
