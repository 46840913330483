<template>
  <div>

    <div>www.pickitperfect.com Terms of Use</div>
    <p><strong>Last updated August 19, 2024</strong></p>

    <p><strong>PLEASE READ THESE TERMS OF USE CAREFULLY.</strong></p>
    <p>These are the Terms of Use (the “Terms”) for using and accessing our websites, platform, software, content, products, applications (mobile or otherwise), social media accounts, forums, or other services (collectively, the “Site”, or “Services”) provided by Pick it Perfect, LLC, and its affiliated companies (hereinafter, collectively, “Pick it Perfect,” “Website,” “Company,” “We,” or “Us”). By accessing and/or utilizing our Website, you agree to be bound by these Terms. If you do not agree to these Terms, then you must not use our Website or any of our services. Further, by accessing and/or utilizing our Website, you will be and are bound to other agreements, such as our Privacy Policy, Cookie Policy, and any other agreements, rules, policies, or terms relating to the Website that we may publish and implement from time to time (collectively, “Other Policies”). Those Other Policies are hereby incorporated into these Terms of Use. If there is a conflict between these Terms and one of the Other Policies, the terms of that Other Policy shall control.</p>

    <p>You may not use the Website if: (1) you are not of legal age or capacity to entering into a binding agreement with us unless you have approval of a guardian, or (2) you are not permitted to receive any services under the laws of the United States of America or other countries or regions including the country or region in which you are a resident or from which you use the services. We reserve the right, in our sole discretion, to terminate, suspend, change, or restrict access to all or any part of our Site without notice or liability.</p>

    <p><strong>PLEASE NOTE THAT THESE TERMS INCLUDE AN ARBITRATION PROVISION, WHICH YOU WILL BE BOUND TO BY USING AND ACCESSING OUR SITE.</strong></p>

    <p><strong><u>CHANGES TO THE TERMS OF USE</u></strong></p>
    <p>Pick it Perfect reserves the right, in its sole discretion, to make changes to these Terms of Use (including the referenced privacy policy and DMCA notice) at any time by posting the revised Terms of Use to the Website. It is your sole responsibility to check the Website from time to time to view any such changes in the Agreement. While we may notify you of changes to these Terms of Use, we are not obligated to do so. Your continued use of the Website after the effective date of such changes constitutes your acceptance of such changes. Pick it Perfect may attempt to place a notice of significant changes to the terms by posting a notice on our homepage and/or sending an email to the email address you provided to us upon registration.</p>

    <p>All notices will be deemed made upon being transmitted or posted regardless of the date of receipt by you. If the modified Terms of Use are not acceptable to you, your only recourse is to cease using the Site.</p>

    <p><strong><u>MONETIZATION OF WEB LINKS</u></strong></p>
    <p>Some links from Pick it Perfect to third-party ecommerce sites are monetized by third-party services. Pick it Perfect may receive "credit" for its part in helping match the buyer with the right item from the right seller for the gift recipient. This monetization is automatic and may or may not be disclosed to You and does not affect the item price. This disclosure is provided in accordance with the United States Federal Trade Commission's 16 CFR § 255.5.</p>

    <p><strong><u>ASSOCIATE DISCLOSURE</u></strong></p>
    <p>Pick it Perfect is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to amazon.com, amazon.co.uk, amazon.ca, amazon.it, amazon.de, and amazon.fr.</p>

    <p>CERTAIN CONTENT THAT APPEARS ON THIS WEBSITE COMES FROM AMAZON SERVICES LLC. THIS CONTENT IS PROVIDED 'AS IS' AND IS SUBJECT TO CHANGE OR REMOVAL AT ANY TIME.</p>

    <p><strong><u>CHILDREN UNDER 13</u></strong></p>
    <p>You must be at least 13 years old to register for and use the Service. To comply with the Children's Online Privacy Protection Act (COPPA), children under 13 years of age should not open their own accounts on this service. Profiles for children under 13 years of age should be created and shared within a parent account. We reserve the right, in our sole discretion, to terminate any accounts created by children under 13 years of age.</p>

    <p><strong><u>CONDITIONS FOR USER CREATION AND USE OF THE SERVICES</u></strong></p>
    <p>When you create a profile at Pick it Perfect and subsequently use the Services, you accept that marketing is displayed on the Services. The marketing may contain:</p>
    <ul>
        <li>Marketing of Pick it Perfect and Pick it Perfect’s services, e.g., special offers from Pick it Perfect or new services offered by Pick it Perfect, or</li>
        <li>Marketing of Pick it Perfect's business partners and those of their products, services, etc., that you have listed on your wish lists or Preference Profile, as well as marketing of our business partners' products and services that resemble or are otherwise related to the items that you have listed on your wish list.</li>
    </ul>

    <p><strong><u>Accessing the Website and Account Security</u></strong></p>
    <p>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict user access, including registered user access, to some parts of the Website or the entire Website.</p>

    <p>You are responsible for both:</p>
    <ul>
        <li>Making all arrangements necessary for you to have access to the Website.</li>
        <li>Ensuring that all persons who access the Website through your internet connection are aware of these Terms of Use and comply with them.</li>
    </ul>

    <p>To access the Website or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete. You agree that all information you provide to register with this Website or otherwise, including, but not limited to, through the use of any interactive features on the Website, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>

    <p><strong><u>Intellectual Property Rights</u></strong></p>
    <p>The Website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Pick it Perfect, LLC, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

    <p><strong><u>Prohibited Uses</u></strong></p>
    <p>You may use the Website only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Website:</p>
    <ul>
        <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
        <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors.</li>
        <li>To transmit any advertising or promotional material, including "spam."</li>
        <li>To impersonate or attempt to impersonate the Company or another user.</li>
    </ul>

    <p><strong><u>User Contributions</u></strong></p>
    <p>The Website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features that allow users to post content or materials ("User Contributions") on or through the Website. All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>

    <p>Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Website, you grant us and our affiliates and service providers, and each of their and our licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material.</p>

    <p><strong><u>Content Standards</u></strong></p>
    <p>User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. User Contributions must not:</p>
    <ul>
        <li>Contain any material that is defamatory, obscene, or otherwise objectionable.</li>
        <li>Promote sexually explicit or pornographic material, violence, or discrimination.</li>
        <li>Violate any legal rights (including rights of privacy) of others.</li>
        <li>Impersonate any person or misrepresent your identity.</li>
    </ul>

    <p><strong><u>Reliance on Information Posted</u></strong></p>
    <p>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy or completeness of this information. Any reliance you place on such information is strictly at your own risk.</p>

    <p><strong><u>Links from the Website</u></strong></p>
    <p>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources and accept no responsibility for them.</p>

    <p><strong><u>Governing Law and Jurisdiction</u></strong></p>
    <p>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto, shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule.</p>

    <p><strong><u>Arbitration</u></strong></p>
    <p>At Company's sole discretion, it may require you to submit any disputes arising from these Terms of Use or use of the Website to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying California law.</p>

    <p><strong><u>Indemnification</u></strong></p>
    <p>You agree to defend, indemnify, and hold harmless the Company, its affiliates, licensors, and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your violation of these Terms of Use.</p>

    <p><strong><u>Entire Agreement</u></strong></p>
    <p>The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and Pick it Perfect LLC regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website.</p>

    <p><strong><u>Contact Information</u></strong></p>
    <p>If you have any questions or concerns about these Terms of Use, please contact us at <a href="mailto:info@pickitperfect.com">info@pickitperfect.com</a>.</p>



  </div>
</template>


<script>

export default {
  // eslint-disable-next-line
  name: 'Terms',
  components: {

  },
  data() {
    return {
    }
  },
  computed: {

  },
	mounted() {

  },
  created() {

  },

}
</script>

<style lang="scss" scoped>


</style>
