<template>
  <b-container class="">
    <b-row>
      <b-col cols="12">
        <div class="text-center mb-5">
          <!-- <img style="width:100%;height:auto;" src="https://storage.googleapis.com/pickitperfect-public-bucket-1/pickitperfect-user-images/logo_motto.png"/> -->
          <h1 class="my-3 pip-main-font" style="font-size:8rem;">Pick it Perfect</h1>
          <h2 class="mt-2 mb-5">Never give or receive an unwanted gift again</h2>
          <div class="text-center">
            <div class="mb-5">
              <a href="/accounts/login" style="color:black;text-decoration:none;">
                <b-button variant="primary" class="mb-3 mr-3">Log in</b-button>
              </a>

              <a href="/accounts/register" style="color:black;text-decoration:none;">
                <b-button variant="primary" class="mb-3">Sign up</b-button>
              </a>

            </div>
            <p>Tell your friends about Pick it Perfect!</p>
            
            <b-button variant="primary" @click="copyToClipboard()"><b-icon icon="share" class="mr-2"></b-icon>Share a link</b-button>
          </div>

        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <h1>Give and receive gifts the Pick it Perfect way</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6" cols="12">
        <p>Say goodbye to the stress of giving and receiving unwanted gifts with Pick it Perfect! Our game-changing Preference Profile streamlines the gift-giving process, making it a piece of cake to choose thoughtful gifts AND receive ones you'll love. Not sure what to get your friend for their birthday? Don't stress! Check out their profile and know exactly what they're into. Let your friends know exactly what you want too by creating your own list of preferences and sharing specific items on your wish list. Plus, with complete control over privacy, you decide who sees what - whether it's everyone or just a few close friends. Never miss the mark on a gift again. Pick it Perfect makes gift-giving easy and personal, without any of the awkwardness.</p>
        <h3>Happy gifting!</h3>
      </b-col>
      <b-col md="6" cols="12">
        <img style="width:100%;height:auto;" src="../../assets/pip_woman.png" />
        
      </b-col>
      <div style="margin-bottom:7rem;"></div>
    </b-row>


    <b-row class="mb-5">
      <b-col md="6" cols="12">
        <img style="width:100%;height:auto;" src="../../assets/pip_present_arms.png" />
      </b-col>
      <b-col md="6" cols="12">
        <h3>How it works</h3>
        <div>
          <div>Follow these simple steps to create a personalized profile that enables your loved ones to choose gifts you actually want.</div>
          <br>
          <div class="pl-5 mb-2">1.<span class="mr-3"></span>  Begin by sharing your personal preferences - there are many options, so feel free to fill out only the ones that matter to you. You can also create your own preference categories.</div>
          <div class="pl-5 mb-2">2.<span class="mr-3"></span>   Add your sizes to ensure your gifts fit.</div>
          <div class="pl-5 mb-2">3.<span class="mr-3"></span>   Enter any items that you would rather not be gifted. Hate the color orange? Let everyone know!</div>
          <div class="pl-5 mb-2 mb-3">4.<span class="mr-3"></span>   Establish personal wish lists. If you have specific items in mind, this is the perfect place to list them. You can also import all your wish lists from other sites, so they are all in one place.</div>
          <br>
          <div class="mb-4">Once your profile is ready, share it, connect with friends, and explore other profiles.</div>
        </div>

        <a href="/accounts/register" style="color:black;text-decoration:none;">
          <b-button variant="primary" class="mb-3">Sign up</b-button>
        </a>
        
      </b-col>
      <div style="margin-bottom:7rem;"></div>
    </b-row>

    <b-alert
      :show="dismissCountDown"
      dismissible
      @dismissed="dismissCountDown=0"
      variant="primary"
      style="position:fixed;bottom:7.5vh;right:15vw;width:300px;height:90px;"
    >
      <div href="#" class="">{{ alertMessage }}</div>
    </b-alert>


  </b-container>
</template>

<script>
// import ContactFormAnonymous from '../components/ContactFormAnonymous.vue'
// import SmileySvg from '../../assets/icons/SmileySvg.vue'

export default {
  name: 'MarketingPage',
  components: {
    // SmileySvg
    // ContactFormAnonymous
  },
  data() {
    return {

      // alert things
      alertMessage: '',
      dismissSecs: 5,
      dismissCountDown: 0,      

    }
  },
  computed: {
    // currentUser() {
    //   return this.$store.state.currentUser
    // },
  },
	mounted() {

  },
  created() {

  },
  watch: {

  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText("https://www.pickitperfect.com/#/")
      // navigator.clipboard.writeText(`${process.env.VUE_APP_HOST}/public#/trips/${this.trip.uuid}/calendar`)
      this.dismissCountDown = this.dismissSecs
      this.alertMessage = 'Link copied to clipboard!'
    },

  }
}
</script>

<style lang="scss" scoped>

span {
  font-weight: 400;
  font-family: Larsseit-Thin;
  // thin is thinner than light
  // font-family: Larsseit-Light;
}

.card {
  border-radius: 0.75rem;
}


div, span {
  font-family: Larsseit-Light;
}

.regular {
  font-size: 1.25rem;
}
.large {
  font-size: 2rem;
}

.small {
  font-size: 1rem;
}


.pip-font-main {
    font-family: 'FionaFont', sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: "Forum", serif;
    font-style: normal;
    font-weight: 400;    
}

.pip-button-font { 
    font-family: "Cormorant Garamond", serif;
    font-size: 20px;
}

</style>
